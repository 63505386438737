import React, {FC} from "react";
import { Box } from '@chakra-ui/react'
import {css} from "@emotion/react";
import {colors} from "../../constants/colors";

export const Footer: FC = () => {
  return (<Box css={styles.copyright}>© 2022 Iwatsuki Student Project</Box>)
}

const styles = {
  copyright: css({
    fontSize: "12px",
    color: colors.black["700"],
    marginBottom: "10px",
    padding: "20px 5px",
    marginTop: "40px !important",
    borderTop: "solid 1px var(--chakra-colors-gray-200)",
    width: "100%"
  })
}