import {FC} from "react";
import {HStack, Link, Text} from "@chakra-ui/react";
import {DownloadIcon} from "@chakra-ui/icons";
import * as React from "react";
import {css} from "@emotion/react";
import {colors} from "../../constants/colors";

type Props = {
  downloadUrl: string;
  fileName: string;
}
export const DownloadButton: FC<Props> = ({downloadUrl, fileName}) => {
  return (<Link href={downloadUrl} download={fileName} css={styles.link}><HStack css={styles.downloadButton}><DownloadIcon mr={1}/><Text>ダウンロード</Text></HStack></Link>)
}

const styles = {
  link: css({
    "&:hover": {
      textDecoration: "none",
    },
  }),
  downloadButton: css({
    borderRadius: "4px",
    padding: "5px 0",
    backgroundColor: colors.brand["400"],
    color: "#FFF",
    flexBasis: "auto",
    fontSize: "14px",
    fontWeight: "bold",
    width: "135px",
    justifyContent: "center",
    transition: "opacity .3s ease",
    "&:hover": {
      opacity: 0.8
    },
  }),
}