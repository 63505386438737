import React, {FC, ReactChildren} from "react";
import { HStack, Box } from "@chakra-ui/react";
import {GlobalSidebar} from "./GlobalSidebar";
import {css} from "@emotion/react";

type Props = {
  url: string,
  onSelectSection: (sectionUrl: string) => void;
}
export const Container : FC<Props> = ({children, url, onSelectSection}) => {
  return <HStack maxWidth={"1200px"} height={"100%"} alignItems={"flex-start"} margin={"auto"}>
    <Box css={styles.sidebarContainer}>
      <GlobalSidebar url={url} onSelectSection={onSelectSection} />
    </Box>
    <Box flex={1} pt={"60px"} pb={5} pr={9} pl={"236px"}>{children}</Box>
  </HStack>
};
const styles = {
  sidebarContainer: css({
    position: "fixed"
  }),
}