import React, { FC } from "react";
import { Box, Image, VStack, Link, Text } from "@chakra-ui/react";
import { css, jsx } from "@emotion/react";
import { colors } from "../../constants/colors";
import {Footer} from "./Footer";

type Props = {
  url: string,
  onSelectSection: (sectionUrl: string) => void;
};

export const GlobalSidebar: FC<Props> = ({url, onSelectSection}) => {
  return (<Box w={"200px"} h={"100%"}>
    <Image src="/logo.png" w={125} />
    <VStack align="flex-start" p={5}>
      <Text css={styles.pageTitle}>はじめに</Text>
      <VStack css={styles.sectionContainer} align="flex-start">
        <Link css={[styles.sectionTitle, (url === "/#overview" || url === "/") && styles.activeTitle]} href="/#overview" onClick={() => onSelectSection("/#overview")}>プロジェクト概要</Link>
      </VStack>
      <Text css={styles.pageTitle}>岩槻建物調査</Text>
      <VStack css={styles.sectionContainer} align="flex-start">
        <Link css={[styles.sectionTitle, (url === "/building#date" || url === "/building") && styles.activeTitle]} href="/building#date" onClick={() => onSelectSection("/building#date")}>日付</Link>
        <Link css={[styles.sectionTitle, url === "/building#purpose" && styles.activeTitle]} href="/building#purpose" onClick={() => onSelectSection("/building#purpose")}>調査目的</Link>
        <Link css={[styles.sectionTitle, url === "/building#overview" && styles.activeTitle]} href="/building#overview" onClick={() => onSelectSection("/building#overview")}>調査概要</Link>
        <Link css={[styles.sectionTitle, url === "/building#result" && styles.activeTitle]} href="/building#result" onClick={() => onSelectSection("/building#result")}>調査結果資料</Link>
      </VStack>
      <Text css={styles.pageTitle}>岩槻交通量調査</Text>
      <VStack css={styles.sectionContainer} align="flex-start">
        <Link css={[styles.sectionTitle, url === "/traffic#date" && styles.activeTitle]} href="/traffic#date" onClick={() => onSelectSection("/traffic#date")}>日付</Link>
        <Link css={[styles.sectionTitle, url === "/traffic#purpose" && styles.activeTitle]} href="/traffic#purpose" onClick={() => onSelectSection("/traffic#purpose")}>調査目的</Link>
        <Link css={[styles.sectionTitle, url === "/traffic#place" && styles.activeTitle]} href="/traffic#place" onClick={() => onSelectSection("/traffic#place")}>調査場所</Link>
        <Link css={[styles.sectionTitle, url === "/traffic#method" && styles.activeTitle]} href="/traffic#method" onClick={() => onSelectSection("/traffic#method")}>調査方法</Link>
        <Link css={[styles.sectionTitle, url === "/traffic#classification" && styles.activeTitle]} href="/traffic#classification" onClick={() => onSelectSection("/traffic#classification")}>調査区分</Link>
        <Link css={[styles.sectionTitle, url === "/traffic#result" && styles.activeTitle]} href="/traffic#result" onClick={() => onSelectSection("/traffic#result")}>調査結果資料</Link>
      </VStack>
      <Footer />
    </VStack>
  </Box>)
}

const styles = {
  pageTitle: css({
    marginTop: "20px !important",
    fontSize: 15,
    color: colors.brand["400"],
    padding: "5px",
    width: "100%",
    fontWeight: "bold",
  }),
  sectionContainer: css({
    marginTop: "0 !important",
    width: "100%",
  }),
  sectionTitle: css({
    fontSize: 15,
    color: colors.black["700"],
    transition: "color .3s ease",
    padding: "5px",
    borderRadius: "3px",
    width: "100%",
    marginTop: "0 !important",
    ":hover": {
      color: colors.brand["400"],
      textDecoration: "none",
    }
  }),
  activeTitle: css({
    color: colors.brand["400"],
    backgroundColor: colors.brand["300"],
  })
};