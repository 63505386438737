import * as React from "react"
import { Routes, Route } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from "@chakra-ui/react"
import { Logo } from "../Logo"
import {Introduction} from "./pages/Introduction";
import {BuildingSurvey} from "./pages/BuildingSurvey";
import {TrafficVolumeSurvey} from "./pages/TrafficVolumeSurvey";

export const App = () => (
  <ChakraProvider theme={theme}>
    {/*<Box textAlign="center" fontSize="xl">*/}
    {/*  <Grid minH="100vh" p={3}>*/}
    {/*    <VStack spacing={8}>*/}
    {/*      <Logo h="40vmin" pointerEvents="none" />*/}
    {/*    </VStack>*/}
    {/*  </Grid>*/}
    {/*</Box>*/}
    <Routes>
      <Route path="/" element={<Introduction />} />
      <Route path="/building" element={<BuildingSurvey />} />
      <Route path="/traffic" element={<TrafficVolumeSurvey />} />
    </Routes>
  </ChakraProvider>
)
