import * as React from "react"
import {FC, useState} from "react";
import {Text, Box, Heading, Image, HStack, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Spacer} from '@chakra-ui/react'
import {Container} from "../components/Container";
import {css} from "@emotion/react";
import {colors} from "../../constants/colors";
import {currentPath} from "../../helper/url";
import {DownloadButton} from "../components/DownloadButton";

type Props = {
};
export const BuildingSurvey: FC<Props> = () => {
    const [currentUrl, setCurrentUrl] = useState<string>(currentPath());
    return <Container url={currentUrl} onSelectSection={setCurrentUrl}>
        <Box>
            <Heading as={"h1"} fontSize={"4xl"} mb={9}>岩槻建物調査</Heading>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"date"}>日付</Heading>
            <Text css={styles.p}>
                建物調査は、2021年の９月～11月にかけて学生4人で実際にまちを歩いておこなった。
            </Text>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"purpose"}>調査目的</Heading>
            <Text css={styles.p}>
                店内で飲食可能であるかなど独自の視点からより正確で詳細なデータを集めることで、岩槻のまちでお店を営業している方々にとって有益な情報を提供するため。
            </Text>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"overview"}>調査概要</Heading>
            <Text css={styles.p}>
                日光御成道、栄町商店街、駅前通り沿いに建てられている286戸に対して調査を実施し、より詳細で正確な建物データの作成を行った。
            </Text>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"result"}>調査結果資料</Heading>
            <HStack w={"100%"}>
              <Image src={"/buildingSurvey/図_商業施設詳細.jpg"} width={"50%"}/>
              <Image src={"/buildingSurvey/図_商業施設詳細説明.jpg"} width={"50%"}/>
            </HStack>

            <Image src={"/buildingSurvey/図_駅前通り.jpg"}/>
            <Image src={"/buildingSurvey/図_栄町商店街.jpg"}/>
            <Image src={"/buildingSurvey/図_市宿通り.jpg"}/>
            <Image src={"/buildingSurvey/図_久保宿通り.jpg"}/>

            <Box css={styles.downloadTableContainer}>
                <Table variant='simple'>
                    <TableCaption>CSVデータ</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>資料名</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>建物調査データ.xlsx</Td>
                            <Td w={"140px"}><DownloadButton downloadUrl={"/buildingSurvey/建物調査データ.xlsx"} fileName={"建物調査データ.xlsx"}/></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
        </Box>
    </Container>
}
const styles = {
    p: css({
    fontSize: "14px",
    color: colors.black["900"],
    marginBottom: "10px"
    }),
    imageContainer: css({
        marginTop: "10px"
    }),
    downloadTableContainer: css({
      borderRadius: "10px",
      border: `solid 1px var(--chakra-colors-gray-200)`,
      marginTop: "30px"
    }),
    downloadButton: css({
      borderRadius: "4px",
      padding: "5px 0",
      backgroundColor: colors.brand["400"],
      color: "#FFF",
      flexBasis: "auto",
      fontSize: "14px",
      fontWeight: "bold",
      width: "135px",
      justifyContent: "center"
    }),
}