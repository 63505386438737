import React, {FC, useState} from "react";
import { Text, Box, Heading, Spacer } from '@chakra-ui/react'
import {Container} from "../components/Container";
import {css} from "@emotion/react";
import {colors} from "../../constants/colors";
import {currentPath} from "../../helper/url";
import {Footer} from "../components/Footer";

type Props = {
};
export const Introduction: FC<Props> = () => {
    const [currentUrl, setCurrentUrl] = useState<string>(currentPath());
    return <Container url={currentUrl} onSelectSection={setCurrentUrl} >
         <Heading as={"h1"} fontSize={"4xl"} mb={9} id={"overview"}>はじめに</Heading>
         <Text css={styles.p}>
           このサイトは2021年に行った、芝浦工業大学の有志のプロジェクトの結果をまとめたものです。
         </Text>
         <Text css={styles.p}>
           ISP(Iwatsuki Student Project)は、芝浦工業大学システム理工学部 環境システム学科に所属する4名(有賀・荻野・池澤・畠)から構成されています。この内3人は岩槻家守舎が経営をするカフェChaToraでアルバイトをしており、地域の人との交流やまち歩きを通して、岩槻についてよく考えるようになりました。次第に、岩槻をもっと良くしたい、もっと心地よい場所にしたいという思いが芽生えました。この思いから、岩槻の活性化に少しでも貢献するために、2021年5月から活動を開始しました。
         </Text>
         <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9}>プロジェクト概要</Heading>
         <Text css={styles.p}>
           このサイトでは2021年度に行った調査（建物用途・交通量）の内容を公開しています。データは「オープンデータ」となっており、誰もが使いやすく見やすい形式で公開をしています。これらのデータを見ることによって、岩槻の意外な特徴がわかったり、新たな発見があったりするかもしれません。このサイトを訪れたことによって、少しでも岩槻について関心を持っていただけることをISPは願っております。
         </Text>
         <Text css={styles.p}>
           このサイトはISPが主体となり、独自に行った調査の内容になります。そのため、データの完全性、正確性、安全性に関していかなる保証をするものではありません。第三者によって提供された情報により生じる一切の損害について、ISPは、何ら責任を負うものではありません。
         </Text>
    </Container>
}

const styles = {
  p: css({
    fontSize: "14px",
    color: colors.black["900"],
    marginBottom: "10px"
  })
}