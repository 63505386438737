export const colors = {
  brand: {
    500: '#00598E',
    400: '#007AC4',
    300: '#EAF7FF',
  },
  black: {
    900: "#000000",
    800: "#333333",
    700: "#666666",
    600: "#888888",
  }
}