import * as React from "react"
import {FC, useState} from "react";
import {Text, Box, Heading, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Link, HStack } from '@chakra-ui/react'
import {Container} from "../components/Container";
import {css} from "@emotion/react";
import {colors} from "../../constants/colors";
import {currentPath} from "../../helper/url";
import {DownloadButton} from "../components/DownloadButton";

type Props = {
};
export const TrafficVolumeSurvey: FC<Props> = () => {
    const [currentUrl, setCurrentUrl] = useState<string>(currentPath());
    return <Container url={currentUrl} onSelectSection={setCurrentUrl}>
            <Heading as={"h1"} fontSize={"4xl"} mb={9}>岩槻交通量調査</Heading>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"date"}>日付</Heading>
            <Text css={styles.p}>
                2021年10月20日,23日の平日と休日の二日間<br/>
                時間は9:00~19:00まで<br/>
                対象は自動車、自転車、歩行者<br/>
            </Text>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"purpose"}>調査目的</Heading>
            <Text css={styles.p}>
                岩槻における交通量の概要を把握し、今後の岩槻駅周辺の街路計画等に役立てるため。
            </Text>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"place"}>調査場所</Heading>
            <Text css={styles.p}>
                駅前通り、日光御成街道、栄町商店街、裏小路の4つの通り
            </Text>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"method"}>調査方法</Heading>
            <Text css={styles.p}>
                各地点で5分間に渡り通過交通量をカウントし、それを1時間の推定交通量とした。
            </Text>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"classification"}>調査区分</Heading>
            <Text css={styles.p}>
                自動車：乗用車、タクシー、バス、トラック、その他<br/>
                自転車：一般的な自転車、スポーツタイプ<br/>
                歩行者：0~15歳、16~29歳、30~65歳、65歳~<br/>
            </Text>
            <Heading as={"h2"} fontSize={"2xl"} mb={4} mt={9} id={"result"}>調査結果資料</Heading>
          <Box css={styles.downloadTableContainer}>
            <Table variant='simple'>
                <TableCaption>CSVデータ</TableCaption>
                <Thead>
                    <Tr>
                        <Th>資料名</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                      <Td>栄町商店街交通量調査シート10月20日（平日）.xlsx</Td>
                      <Td w={"140px"}><DownloadButton downloadUrl={"/trafficVolumeSurvey/栄町商店街交通量調査シート10月20日（平日）.xlsx"} fileName={"栄町商店街交通量調査シート10月20日（平日）.xlsx"}/></Td>
                    </Tr>
                    <Tr>
                      <Td>栄町商店街交通量調査シート10月23日（祝日）.xlsx</Td>
                      <Td w={"140px"}><DownloadButton downloadUrl={"/trafficVolumeSurvey/栄町商店街交通量調査シート10月23日（祝日）.xlsx"} fileName={"栄町商店街交通量調査シート10月23日（祝日）.xlsx"}/></Td>
                    </Tr>
                </Tbody>
            </Table>
          </Box>
    </Container>
}

const styles = {
    p: css({
        fontSize: "14px",
        color: colors.black["900"],
        marginBottom: "10px"
    }),
    downloadTableContainer: css({
       borderRadius: "10px",
       border: `solid 1px var(--chakra-colors-gray-200)`
    }),
    downloadButton: css({
      borderRadius: "4px",
      padding: "5px 0",
      backgroundColor: colors.brand["400"],
      color: "#FFF",
      flexBasis: "auto",
      fontSize: "14px",
      fontWeight: "bold",
      width: "135px",
      justifyContent: "center"
    }),
}