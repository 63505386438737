import {ColorModeScript, extendTheme} from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import { App } from "./presentation/App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom';
import {colors} from "./constants/colors";

const styles = {
  global: {
    html: {
      color: colors.black["900"],
      display: "flex",
      flex: 1,
      fontFamily:
        '-apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Meiryo", "Segoe UI Symbol", sans-serif',
      height: "auto",
      minHeight: "100%",
      wordBreak: "break-word",
    },
    body: {
      boxSizing: "border-box",
      height: "auto",
      margin: 0,
      minHeight: "100%",
      width: "100%",
      lineHeight: 1.5
    },
    h1: {
      fontWeight: "bold",
    },
    a: {
      color: colors.brand,
    },
  }
}

const theme = extendTheme({ colors, styles });

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <Router>
      <ChakraProvider theme={theme}>
         <App />
      </ChakraProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
